import { useEffect, useState } from "react";
import { Router, useRouter } from "next/router";
import { useUser } from "@auth0/nextjs-auth0";
import { identifyUser, trackPage } from "utils/tracking";
import { useUpdateAccountMutation } from "generated/graphql";
import { createUrlqClient } from "utils/graphqlClient/createUrqlClient";
import { ssrExchange } from "urql";
import { withUrqlClient } from "next-urql";

// Track client-side page views
Router.events.on("routeChangeComplete", trackPage);

const WatcherComponent = () => {
  const [identified, setIdentified] = useState(false);
  const { user } = useUser();
  const { locale } = useRouter();

  const [_account, updateAccount] = useUpdateAccountMutation();

  useEffect(() => {
    if (user && locale) {
      if (user.locale !== locale && user.sub) {
        // update profile to set locale
        updateAccount({ id: user.sub, account: { locale } });
      }
    }
  }, [user, locale, updateAccount]);

  // identify user once per full page load
  if (user && !identified) {
    setIdentified(true);
    identifyUser({ user });
  }
  return <></>;
};

export const Watcher = withUrqlClient(createUrlqClient)(WatcherComponent);
