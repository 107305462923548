import {
  OptimisticMutationConfig,
  OptimisticMutationResolver
} from "@urql/exchange-graphcache/dist/types/types";

// When a favorite is added, assume that'll work
// and just add one to the cached number of likes
// return something quickly so that the cache updates without waiting for network
const insert_favorite_articles_one: OptimisticMutationResolver = (
  _variables,
  _cache,
  _info
) => ({
  __typename: "favorite_articles",
  id: 0
});

// When a favorite is removed, return quicklyy
const update_favorite_articles: OptimisticMutationResolver = (
  _variables,
  _cache,
  _info
) => ({
  __typename: "favorite_articles",
  id: 0
});

export const optimisticMutations: OptimisticMutationConfig = {
  insert_favorite_articles_one,
  update_favorite_articles
};
