import { cacheExchange } from "@urql/exchange-graphcache";
import { dedupExchange, Exchange, fetchExchange } from "urql";
import { devtoolsExchange } from "@urql/devtools";
import { pipe, tap } from "wonka";
import Router from "next/router";
import { isServer } from "../isServer";
import { NextUrqlClientConfig } from "next-urql";
import { mutationUpdates } from "./mutationUpdates";
import { subscriptionUpdates } from "./subscriptionUpdates";
import { optimisticMutations } from "./optimisticMutations";
import { queryResolvers } from "./queryResolvers";

const errorExchange: Exchange = ({ forward }) => (ops$) => {
  return pipe(
    forward(ops$),
    tap(({ error }) => {
      if (error) {
        if (error?.message.includes("Not authenticated")) {
          Router.replace("/login");
        }
      }
    })
  );
};

export const createUrlqClient: NextUrqlClientConfig = (ssrExchange) => {
  return {
    url:
      isServer() && process.env.GRAPHQL_URL
        ? process.env.GRAPHQL_URL
        : "/api/graphql",
    exchanges: [
      devtoolsExchange,
      dedupExchange,
      cacheExchange({
        keys: {
          learning_journey_progress_aggregate: () => null,
          learning_journey_progress_aggregate_fields: () => null,
        },
        resolvers: {
          ...queryResolvers,
        },
        optimistic: {
          ...optimisticMutations,
        },
        updates: {
          ...mutationUpdates,
          ...subscriptionUpdates,
        },
      }),
      errorExchange,
      ssrExchange,
      fetchExchange,
    ],
  };
};
