import { UserProfile } from "@auth0/nextjs-auth0";

const DEBUG = false;

export type EventNames =
  | "Logged in" //server
  | "Registered" //server
  | "Completed Onboarding" //server
  | "Added Favorite Article" // client
  | "Changed Language" // client
  | "Prepayment Booking Reservation" //server
  | "Booked Free Session" // server
  | "Booked Paid Session" // server
  | "Bought Tokens" // server
  | "Subscribed Monthly" // server
  | "Subscribed Annually" // server
  | "Bought Premium Package" //server
  | "Stopped Learning Journey Video" //client
  | "Completed Learning Journey Video" // client
  | "Completed Learning Journey" // client
  | "Completed Post-Learning Journey Survey"; // client

export interface UserMetadata {
  firstName?: string | null;
  loginsCount?: number | null;
}

interface EventProperties {
  [key: string]: any;
  revenue?: number;
  currency?: string;
  value?: number;
}

/* Track a client-side page navigation */
// This is hooked onto the Router.events method in watcher.tsx
// Nothing to do here, it will run by itself
const trackPage = (url: string) => {
  if (DEBUG) {
    console.log("page tracked ", url);
  }
  window?.analytics?.page(url, {
    integrations: {
      All: true,
      Slack: false,
    },
  });
};

/*  Track an event */
// Available event names are listed in the type
// These are not restricted by Segment, but just to help conformity
// Add a call to this method every time you want to track an event
const trackEvent = (eventName: EventNames, properties: EventProperties) => {
  if (DEBUG) {
    console.log("event tracked ", eventName);
  }
  window?.analytics?.track(eventName, properties);
};

/* Identify a user to segment */
// Segment recommends that you make an Identify call:
// After a user first registers
// After a user logs in
// When a user updates their info (for example, they change or add a new address)
// Upon loading any pages that are accessible by a logged in user (optional)
const identifyUser = ({ user }: { user: UserProfile }) => {
  if (DEBUG) {
    console.log("user identified ", user.sub);
  }
  if (!user.sub) {
    throw Error("User is not identifiable");
  }
  const firstName = (user[
    "https://hasura.io/jwt/claims/user_metadata"
  ] as UserMetadata)?.firstName;

  window?.analytics?.identify(user.sub, {
    name: user.name,
    email: user.email,
    userType: user.user_type,
    firstName,
    integrations: {
      All: true,
      Slack: false,
    },
  });
};

export { trackEvent, trackPage, identifyUser };
