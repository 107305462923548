import { appWithTranslation } from "next-i18next";
import { AppProps } from "next/app";
import { UserProvider } from "@auth0/nextjs-auth0";
import { ChakraProvider } from "@chakra-ui/react";
import { Watcher } from "utils/tracking/watcher";
import Router from "next/router";
import theme from "theme";
import NProgress from "nprogress";
import "theme/nprogress.css";

//Binding events.
NProgress.configure({ showSpinner: false });

Router.events.on("routeChangeStart", () => NProgress.start());
Router.events.on("routeChangeComplete", () => NProgress.done());
Router.events.on("routeChangeError", () => NProgress.done());

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <UserProvider>
      <ChakraProvider resetCSS theme={theme}>
        <Component {...pageProps} />
      </ChakraProvider>
      <Watcher pageProps={null} />
    </UserProvider>
  );
}

export default appWithTranslation(MyApp);
