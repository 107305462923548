import { simplePagination } from "@urql/exchange-graphcache/extras";

export const queryResolvers = {
  Query: {
    coach_availability: simplePagination({
      limitArgument: "limit",
      offsetArgument: "offset"
    })
  }
};
