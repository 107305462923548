/* eslint-disable camelcase */
/* if you want your mutation to update the cache, add a function here */

import { gql } from "urql";
import Router from "next/router";
import {
  UpdateResolver,
  UpdatesConfig,
} from "@urql/exchange-graphcache/dist/types/types";
import {
  AddArticleViewMutation,
  AddFavoriteArticleMutationVariables,
  ArticleFavoriteDocument,
  ArticleFavoriteQuery,
  ViewedArticlesDocument,
  ViewedArticlesQuery,
  UpdateCoachAvailabilityMutation,
  InsertCoachAvailabilitiesMutation,
  CoachAvailabilityWithAccountDocument,
  CoachAvailabilityWithAccountQuery,
  AccountBalanceDocument,
  AccountBalanceQuery,
  SaveQuizAnswersMutation,
  SaveQuizAnswersMutationVariables,
  LastQuizResponseQuery,
  LastQuizResponseDocument,
  FavoriteArticlesQuery,
  FavoriteArticlesDocument,
  JourneyProgressDocument,
  JourneyProgressQuery,
} from "../../generated/graphql";

/* update the favorites when you add one */
const insert_favorite_articles_one: UpdateResolver = (result, args, cache) => {
  cache.updateQuery(
    {
      query: ArticleFavoriteDocument,
      variables: {
        articleId: (args.object as AddFavoriteArticleMutationVariables)
          .article_id,
      },
    },
    (data: ArticleFavoriteQuery | null) => {
      if (!data) {
        return data;
      }
      if (!data.favorite_counts.length) {
        data.favorite_counts = [
          {
            __typename: "favorite_counts",
            id: 0,
            count: 1,
          },
        ];
      } else {
        data.favorite_counts[0].count = data.favorite_counts[0].count + 1;
      }
      if (!data.favorite_articles.length) {
        data.favorite_articles = [
          {
            __typename: "favorite_articles",
            id: 0,
            article_id: (args.object as AddFavoriteArticleMutationVariables)
              .article_id,
            updated_at: new Date(),
          },
        ];
      } else {
        data.favorite_articles.push({
          __typename: "favorite_articles",
          id: 0,
          article_id: (args.object as AddFavoriteArticleMutationVariables)
            .article_id,
          updated_at: new Date(),
        });
      }
      return data;
    }
  );

  const faveId = (result.insert_favorite_articles_one as any)?.id;
  if (faveId !== 0) {
    cache.updateQuery(
      {
        query: FavoriteArticlesDocument,
      },
      (data: FavoriteArticlesQuery | null) => {
        if (!data) {
          return data;
        }
        if (data.favorite_articles.some((fave) => fave.id === faveId)) {
          return data;
        }
        data.favorite_articles.push({
          __typename: "favorite_articles",
          id: faveId,
          article_id: (args.object as AddFavoriteArticleMutationVariables)
            .article_id,
        });
        return data;
      }
    );
  }
};

/* update the favorites when you remove one */
const update_favorite_articles: UpdateResolver = (result, args, cache) => {
  const articleId = (args.where as any).article_id._eq;
  cache.updateQuery(
    {
      query: ArticleFavoriteDocument,
      variables: {
        articleId,
      },
    },
    (data: ArticleFavoriteQuery | null) => {
      if (!data) {
        return data;
      }
      if (data.favorite_counts.length) {
        data.favorite_counts[0].count = data.favorite_counts[0].count - 1;
      }
      if (data.favorite_articles.length) {
        data.favorite_articles = data.favorite_articles.filter(
          (article) => article.article_id !== articleId
        );
      }
      return data;
    }
  );
  const faveId = (result?.update_favorite_articles as any)?.returning?.[0]?.id;
  if (faveId) {
    cache.updateQuery(
      {
        query: FavoriteArticlesDocument,
      },
      (data: FavoriteArticlesQuery | null) => {
        if (!data) {
          return data;
        }
        const result = data.favorite_articles.filter(
          (fave) => fave.id !== faveId
        );
        data.favorite_articles = result;
        return data;
      }
    );
  }
};

/* update the coach availability query cache when you update coach availability */
const update_coach_availability_by_pk: UpdateResolver = (
  result: UpdateCoachAvailabilityMutation,
  _args,
  cache
) => {
  if (!result) {
    return;
  }
  const fragment = gql`
    fragment _ on coach_availability {
      id
      available
    }
  `;
  cache.writeFragment(fragment, {
    id: result.update_coach_availability_by_pk?.id,
    available: result.update_coach_availability_by_pk?.available,
  });
};

/* when new days are added to a coaches calendar, update the graphql cache of calendar availability */
const insert_coach_availability: UpdateResolver = (
  result: InsertCoachAvailabilitiesMutation,
  args,
  cache
) => {
  if (!result) {
    return;
  }

  const date = new Date();
  date.setDate(date.getDate() - 1);
  const textDate = date.toISOString().split("T");
  const today = textDate[0];
  const coach_id = (args as any).objects?.[0]?.coach_id;

  cache.updateQuery(
    {
      query: CoachAvailabilityWithAccountDocument,
      variables: {
        coachIds: [coach_id],
        today,
      },
    },
    (data: CoachAvailabilityWithAccountQuery | null) => {
      if (!result.insert_coach_availability || !data) {
        return data;
      }
      result.insert_coach_availability.returning.forEach((res) => {
        data.coach_availability.push({
          ...res,
          account: null,
          isFuture: true,
        });
      });
      return data;
    }
  );
};

/* update the article view cache and the account balance query when we make the article view mutation */
const insert_account_article_view_one: UpdateResolver = (
  result: AddArticleViewMutation,
  _args,
  cache
) => {
  cache.updateQuery(
    { query: ViewedArticlesDocument },
    (data: ViewedArticlesQuery | null) => {
      if (!result.insert_account_article_view_one || !data) {
        return data;
      }
      data.account_article_view.push(result.insert_account_article_view_one);
      return data;
    }
  );

  cache.updateQuery(
    { query: AccountBalanceDocument, variables: { locale: Router.locale } },
    (data: AccountBalanceQuery | null) => {
      if (!result.insert_account_article_view_one || !data) {
        return data;
      }
      const response = { ...data };
      const index = data.account[0].recommended_articles.findIndex(
        (article) =>
          article.article_id ===
          result.insert_account_article_view_one?.article_id
      );

      if (index === -1) {
        return response;
      }

      if (!response.account[0].recommended_articles[index].lastViewed) {
        response.account[0].recommended_articles[index].lastViewed = {
          __typename: "last_account_article_view",
          id: result.insert_account_article_view_one.id,
          created_at: new Date(),
        };
      }
      return response;
    }
  );
};

/*  invalidate the account cache when we update the account in any way */
const update_account_by_pk: UpdateResolver = (_result, args, cache) => {
  const id = (args.pk_columns as any).id;
  cache.invalidate({
    __typename: "account",
    id,
  });
};

const insert_quiz_response: UpdateResolver = (
  result: SaveQuizAnswersMutation,
  args,
  cache
) => {
  const argObjects = args.objects as SaveQuizAnswersMutationVariables;
  cache.updateQuery(
    {
      query: LastQuizResponseDocument,
      variables: { quizId: argObjects.quiz_id },
    },
    (data: LastQuizResponseQuery | null) => {
      if (
        !result.insert_quiz_response ||
        !data ||
        !result.insert_quiz_response.returning
      ) {
        return data;
      }

      data.last_quiz_response = [
        {
          __typename: "last_quiz_response",
          id: result.insert_quiz_response.returning[0].id,
          answers: argObjects.answers,
          quiz_id: argObjects.quiz_id,
          result_id: argObjects.result_id,
        },
      ];
      return data;
    }
  );
};

const insert_learning_journey_progress_one: UpdateResolver = (
  _result,
  args,
  cache
) => {
  if ((args?.object as any)?.completed === true) {
    const key = "Query";
    const fields = cache.inspectFields(key);
    fields
      .filter(
        (field) => field.fieldName === "learning_journey_progress_aggregate"
      )
      .forEach((field) => {
        cache.invalidate(key, field.fieldName, field.arguments);
      });
  }
};

export const mutationUpdates: Partial<UpdatesConfig> = {
  Mutation: {
    // update the favorites when you add one
    insert_favorite_articles_one,
    // update the favorites when you remove one
    update_favorite_articles,
    // update the coach availability query wih the update mutation
    update_coach_availability_by_pk,
    // when new days are added to a coaches calendar, update the graphql cache of calendar availability
    insert_coach_availability,
    // update the article view cache when we make the article view mutation
    insert_account_article_view_one,
    // invalidate the account cache when we update the account in any way
    update_account_by_pk,
    // update the last inserted quiz result per quiz
    insert_quiz_response,
    // invalidate the learning journey completed count
    insert_learning_journey_progress_one,
  },
};
