import calendar from "./calendar";

const globals = {
  global: {
    body: {
      color: "black",
    },
    "body.noscroll": {
      height: "100%",
      overflow: "hidden",
    },
    "::selection": {
      background: "orange.100",
      color: "black",
    },
    "h1, h2, h3, h4, h5, h6": {
      fontFamily: "Newsreader",
      fontSize: "revert",
    },
    "h1, h2, h3": {
      fontWeight: "bold",
    },
    ".justify": {
      textAlign: "justify",
    },
    "::marker": {
      textIndent: "10px !important",
      textAlign: "start !important",
      textAlignLast: "start !important",
    },
    ".bold": {
      fontWeight: "bold",
    },
    "a:hover": {
      textDecoration: "underline #fa8c44",
    },
    "ul, ol": {
      listStylePosition: "inside",
    },
    ".markdown img": {
      marginLeft: "auto",
      marginRight: "auto",
    },
    ".markdown ol, .markdown ul": {
      paddingLeft: "1.4em",
      margin: "1em 0",
    },

    ".markdown li, .markdown p": {
      marginBottom: "1em",
    },
    ".markdown h1, .markdown h2, .markdown h3, .markdown h4, .markdown h5, .markdown h6": {
      marginTop: "1.2em",
      fontWeight: "bold",
      textAlign: "left",
      lineHeight: "130%",
      marginBottom: "0.3em",
    },
    ".markdown h1": {
      fontSize: "48px",
    },
    ".markdown h2": {
      fontSize: "30px",
    },
    ".markdown h3": {
      fontSize: "24px",
    },
    ".markdown.coaches img": {
      marginTop: "20px",
      maxHeight: "120px",
      maxWidth: "240px",
    },
    ".markdown.restricted": {
      position: "relative",
    },
    ".markdown.restricted::after": {
      content: '" "',
      position: "absolute",
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
      background:
        "linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 80%)",
    },
    ...calendar,
  },
};

export default globals;
