const calendar = {
  ".react-calendar__navigation__next2-button, .react-calendar__navigation__prev2-button": {
    display: "none",
  },
  ".react-calendar": {
    userSelect: "none",
  },
  ".react-calendar abbr[title]": {
    textDecoration: "none",
  },
  ".react-calendar__month-view__weekdays": {
    marginTop: "20px",
  },
  ".react-calendar__navigation": {
    display: "flex",
    justifyContent: "space-between",
    padding: "0 30px",
  },
  ".react-calendar__navigation__arrow": {
    fontSize: "1.2em",
    width: "38px",
    height: "38px",
    borderRadius: "38px",
    background: "#FFFAF0",
    lineHeight: "1em",
    paddingBottom: "3px",
  },
  ".react-calendar__navigation__arrow:hover": {
    background: "#FEEBC8",
  },
  ".react-calendar__tile--now": {
    position: "relative",
  },
  ".react-calendar__tile--now::after": {
    content: "'\\2022'",
    position: "absolute",
    bottom: "-8px",
    fontSize: "1.6em",
    color: "#aeaeae",
  },
  ".react-calendar__navigation__arrow:disabled": {
    pointerEvents: "none",
    background: "transparent",
    visibility: "hidden",
  },
  ".react-calendar__navigation__label, .react-calendar__month-view__weekdays__weekday": {
    fontFamily: "Newsreader",
    fontSize: "1.2em",
  },
  ".react-calendar__month-view__weekdays__weekday": { textAlign: "center" },
  ".react-calendar__tile": {
    padding: "4px 0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  ".react-calendar__tile abbr": {
    background: "#FEEBC8",
    height: "38px",
    width: "38px",
    padding: "6px",
    borderRadius: "25px",
  },
  ".react-calendar__tile:hover abbr": {
    background: "#FBD38D",
  },
  ".react-calendar__tile--active abbr": {
    background: "#FBD38D",
  },
  ".react-calendar__tile:disabled, .react-calendar__navigation__label": {
    pointerEvents: "none",
  },
  ".react-calendar__tile:disabled abbr": {
    background: "transparent",
  },
};

export default calendar;
