import { extendTheme, withDefaultColorScheme } from "@chakra-ui/react";
import { createBreakpoints } from "@chakra-ui/theme-tools";
import styles from "./global";

const fonts = { heading: "Newsreader", body: "Questrial" };

const breakpoints = createBreakpoints({
  sm: "40em",
  md: "52em",
  lg: "64em",
  xl: "80em",
});

const textStyles = {
  h1: {
    fontSize: ["48px", "72px"],
    fontWeight: "bold",
    lineHeight: "110%",
    letterSpacing: "-2%",
  },
  h2: {
    fontSize: ["36px", "48px"],
    fontWeight: "semibold",
    lineHeight: "110%",
    letterSpacing: "-1%",
  },
  h3: {
    fontSize: ["18px", "24px"],
    fontWeight: "semibold",
    lineHeight: "110%",
  },
};

const theme = extendTheme(withDefaultColorScheme({ colorScheme: "orange" }), {
  styles: {
    global: {
      ...styles.global,
      h1: textStyles.h1,
      h2: textStyles.h2,
      h3: textStyles.h3,
    },
  },
  shadows: {
    outline: "0 0 0 1px rgba(250,140,68, 0.3)",
  },
  colors: {
    brand: "#fa8c44",
    beige: "#f4efe6",
    darkBeige: "#F0E2CB",
    facebook: "#4267B2",
    whatsapp: "#128C7E",
    twitter: "#1DA1F2",
    black: "#1d1d1d",
    orange: {
      300: "#FBD38D",
      400: "#F6AD55",
      500: "#fa8c44",
      600: "#EC7C32",
      700: "#DD6B20",
      800: "#C05621",
      900: "#7B341E",
    },
    purple: {
      100: "#d4c8f3",
      200: "#cdbff1",
      300: "#c6b5ef",
      400: "#bfaced",
      500: "#b8a3eb",
      600: "#a693d4",
      700: "#9382bc",
      800: "#8172a5",
      900: "#6e628d",
    },
    grey: {
      50: "#EBE9F0",
      100: "#DADADA",
    },
  },
  textStyles,
  fonts,
  breakpoints,
  components: {
    Button: {
      baseStyle: {
        borderRadius: "3xl",
        lineHeight: 1,
        height: "auto",
        minHeight: 10,
        paddingBlockStart: "10px",
        paddingBlockEnd: "10px",
        whiteSpace: "pre-line",
        _focus: {
          boxShadow: "none",
          outline: "none",
        },
      },
      variants: {
        shadow: {
          padding: ["0 30px", "0 50px"],
          boxShadow: "0 2px 3px 3px #efefef",
          _active: { bg: `orange.500`, color: `white` },
          _hover: { bg: `orange.200` },
          _focus: {
            boxShadow: "0 2px 3px 3px #efefef",
          },
        },
        shadowWrapped: {
          boxSizing: "border-box",
          minHeight: ["90px", 20],
          margin: "0 1em",
          height: "auto",
          whiteSpace: "pre-wrap",
          padding: ["10px 30px", "10px 50px"],
          boxShadow: "0 2px 3px 3px #efefef",
          _active: { bg: `orange.500`, color: `white` },
          _hover: { bg: `orange.200` },
          _focus: {
            boxShadow: "0 2px 3px 3px #efefef",
          },
        },
      },
    },
    Badge: {
      baseStyle: {
        borderRadius: "xl",
        padding: "3px 10px",
      },
    },
    Heading: {
      variants: {
        h1: textStyles.h1,
        h2: textStyles.h2,
        h3: textStyles.h3,
      },
    },
    Text: {
      variants: {
        newsreader: {
          fontFamily: "Newsreader",
        },
        subtitle: {
          fontSize: "xl",
          fontWeight: "medium",
        },
        boldNewsreader: {
          fontFamily: "Newsreader",
          fontWeight: "bold",
        },
        subtitleNewsreader: {
          fontFamily: "Newsreader",
          fontSize: "xl",
          fontWeight: "medium",
        },
        body: {
          fontSize: "md",
          fontWeight: "medium",
        },
      },
    },
    Tooltip: {
      baseStyle: {
        borderRadius: "6px",
        fontWeight: "bold",
        bg: "purple.500",
        "--popper-arrow-bg": "#b8a3eb",
        paddingTop: 2,
        paddingBottom: 2,
      },
    },
    Link: {
      baseStyle: {
        textDecorationColor: "orange.500",
        _hover: {
          textDecoration: "underline",
          textDecorationColor: "orange.500",
        },
        _groupHover: {
          textDecoration: "underline",
          textDecorationColor: "orange.500",
        },
        _focus: {
          boxShadow: "none",
        },
      },
      variants: {
        menu: {
          textTransform: "uppercase",
          fontSize: "14px",
          letterSpacing: "2px",
          margin: "10px 0",
          display: "block",
          outline: 0,
          _focus: {
            boxShadow: "none",
          },
        },
      },
    },
    Progress: {
      baseStyle: {
        transitionProperty: "all",
      },
    },
    Wrapper: {
      variants: {
        quoteLeft: {
          display: "inline",
          position: "relative",
          marginBottom: "38px",
          top: "38px",
          left: "-6px",
          background: "purple.500",
          color: "white",
          padding: "6px 12px",
          borderRadius: "0 6px 6px 6px",
        },
        quoteRight: {
          display: "inline",
          position: "relative",
          marginBottom: "38px",
          top: "38px",
          right: "-6px",
          background: "purple.500",
          color: "white",
          padding: "6px 12px",
          borderRadius: "6px 0 6px 6px",
        },
        quoteVeryRight: {
          display: "inline",
          position: "relative",
          marginBottom: "38px",
          top: "38px",
          right: "-24px",
          background: "purple.500",
          color: "white",
          padding: "6px 24px 6px 12px",
          borderRadius: "6px 0 6px 6px",
        },
        wrapper: {
          marginTop: 8,
          marginBottom: 0,
          marginLeft: "auto",
          marginRight: "auto",
          maxWidth: "64em",
          width: "100%",
          paddingLeft: 4,
          paddingRight: 4,
          flex: 1,
        },
        wrapperNoTop: {
          marginTop: 0,
          marginBottom: 0,
          marginLeft: "auto",
          marginRight: "auto",
          maxWidth: "64em",
          width: "100%",
          paddingLeft: 4,
          paddingRight: 4,
          flex: 1,
        },
        wrapperFullHeight: {
          marginTop: 8,
          marginBottom: 0,
          marginLeft: "auto",
          marginRight: "auto",
          maxWidth: "64em",
          width: "100%",
          paddingLeft: 4,
          paddingRight: 4,
          flexGrow: 1,
          flexDirection: "column",
          display: "flex",
          flex: 1,
        },
        wrapperNoMargins: {
          marginTop: 0,
          marginBottom: 0,
          marginLeft: "auto",
          marginRight: "auto",
          maxWidth: "64em",
          width: "100%",
          paddingLeft: 4,
          paddingRight: 4,
          flex: 1,
        },
        wrapperWide: {
          marginTop: 8,
          marginBottom: 0,
          marginLeft: 0,
          marginRight: 0,
          maxWidth: "100%",
          width: "100%",
          paddingLeft: 0,
          paddingRight: 0,
          flex: 1,
        },
        wrapperWideNoMargins: {
          marginTop: 0,
          marginBottom: 0,
          marginLeft: 0,
          marginRight: 0,
          maxWidth: "100%",
          width: "100%",
          paddingLeft: 0,
          paddingRight: 0,
          flex: 1,
        },
      },
    },
  },

  zIndices: {
    modal: 9999999998,
  },
});

export default theme;
